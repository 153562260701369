import store from '@/store'

class WebSocketService {
  private socket: WebSocket | null = null
  private reconnectInterval = 5000
  private maxReconnectAttempts = 50
  private reconnectAttempts = 0
  private receivedNotificationIds: Set<number> = new Set()
  private pingInterval: number | null = null
  private isReconnecting = false
  private pingTimeout: number | null = null

  private getWebSocketUrl(): string {
    return `${process.env.VUE_APP_WS_URL}/users/${store.state.user.user.id}`
  }

  private getToken(): string | null {
    return localStorage.getItem('auth_token')
  }

  private initializeWebSocket(url: string, token: string): void {
    this.socket = new WebSocket(url)

    this.socket.onopen = () => this.handleOpen(token)
    this.socket.onmessage = (event) => this.handleMessage(event)
    this.socket.onerror = (error) => this.handleError(error)
    this.socket.onclose = () => this.handleClose()
  }

  connect(): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = this.getWebSocketUrl()
      const token = this.getToken()

      if (!url || !token) {
        return reject(new Error('WebSocket URL or auth token is missing'))
      }

      this.initializeWebSocket(url, token)
      resolve()
    })
  }

  private handleOpen(token: string): void {
    console.log('Connected to WebSocket server')
    this.resetReconnectAttempts()
    this.sendToken(token)
    this.startPing()
  }

  private resetReconnectAttempts(): void {
    this.reconnectAttempts = 0
    this.isReconnecting = false
  }

  private sendToken(token: string): void {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(token)
    }
  }

  private startPing(): void {
    this.pingInterval = window.setInterval(() => {
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.socket.send('ping')
        this.setPingTimeout()
      }
    }, 10000)
  }

  private setPingTimeout(): void {
    this.pingTimeout = window.setTimeout(() => {
      console.log('Ping timeout, closing connection')
      this.socket?.close()
    }, 5000)
  }

  private handleMessage(event: MessageEvent): void {
    const message = JSON.parse(event.data)

    if (process.env.NODE_ENV !== 'production') {
      console.log('Received message:', message)
    }

    this.clearPingTimeout()
    this.processMessage(message)
  }

  private clearPingTimeout(): void {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout)
      this.pingTimeout = null
    }
  }

  private processMessage(message: any): void {
    switch (message.type) {
      case 'notification':
        store.commit('notifications/addNotification', message.payload)
        break
      case 'userResourceUpdate':
        store.commit('user/setUserEnergy', message.payload.energy)
        store.commit('user/setUserCoins', message.payload.coins)
        break
      case 'userInvitedFriendsCountUpdate':
        store.commit(
          'user/setUserInvitedFriendsCount',
          message.payload.invitedFriendsCount
        )
        break
      case 'userMembersCountUpdate':
        store.commit('user/setUserMembersCount', message.payload.membersCount)
        break
      case 'userMembershipSquadUpdate':
        store.commit(
          'user/setUserMembershipSquad',
          message.payload.membershipSquad
        )
        break
      case 'userOwningSquadUpdate':
        store.commit('user/setUserOwningSquad', message.payload.owningSquad)
        break
      case 'disconnect':
        store.commit('device/setConnectionClosed', true)
        break
    }
  }

  private handleError(error: Event): void {
    console.error('WebSocket error:', error)
    this.reconnect()
  }

  private handleClose(): void {
    console.log('WebSocket connection closed')
    this.reconnect()
    this.clearPingInterval()
    this.clearPingTimeout()
  }

  private clearPingInterval(): void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval)
      this.pingInterval = null
    }
  }

  private reconnect(): void {
    if (this.canReconnect()) {
      this.reconnectAttempts++
      this.isReconnecting = true

      this.clearPingInterval()
      this.clearPingTimeout()

      if (this.socket) {
        this.socket.close()
      }

      setTimeout(() => {
        this.connect().finally(() => {
          this.isReconnecting = false
        })
      }, this.reconnectInterval)
    } else {
      console.error('Max reconnect attempts reached or disconnected')
    }
  }

  private canReconnect(): boolean {
    return (
      this.reconnectAttempts < this.maxReconnectAttempts &&
      !store.state.device.isConnectionClosed &&
      !this.isReconnecting
    )
  }
}

export default new WebSocketService()

import api from '@/api'

export const getUrlParams = (): {
  mockUserType: string | null
  setAuthToken: string | null
} => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    mockUserType: urlParams.get('mock'),
    setAuthToken: urlParams.get('set-auth-token'),
  }
}

export const getStoredToken = (): string | null =>
  localStorage.getItem('auth_token')

export const handleProductionMode = async () => {
  try {
    const hash = window.location.hash
    const encodedToken = hash.slice(1)

    const token = atob(encodedToken)

    history.replaceState(
      null,
      '',
      window.location.pathname + window.location.search
    )
    if (token) {
      localStorage.setItem('auth_token', token)
    }

    const user = await api.users.me()
    return user
  } catch (error) {
    console.log(error)
  }
}

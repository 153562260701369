import tap from '@/assets/images/text-tap-ru.png'

export default {
  tools: {
    squadLevels: {
      bronze: 'Бронза',
      silver: 'Серебро',
      gold: 'Золото',
      diamond: 'Бриллиант',
    },
    rotateMessage: 'Переверните устройство, пожалуйста',
    disconnectedMessage: 'Вы запустили игру на втором устройстве',
    playOnMobile: 'Запустите игру на\n мобильном устройстве',
  },
  countdown: {
    d: 'Д',
  },
  pageMain: {},
  gameContent: {
    tabFarm: 'Фарм',
    tabTop: 'Рейтинг',
    tabMySquad: 'Мой сквад',
    yourSquadHead: 'Твой сквад',
  },
  tasksList: {
    joinChannel: {
      title: 'Подпишись на&nbsp;канал Hyper',
      buttonText: 'Подписаться',
    },
    invite: {
      title: 'Пригласи друзей',
      buttonText: 'Пригласить',
      terms: 'условия',
    },
    joinSquad: {
      title:
        'Вступи в сквад и&nbsp;получай <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> каждые <b>8</b> часов',
      buttonText: 'Сквады',
    },
    waitSquadEnergy: {
      title: 'Восстановление энергии +{ value } { icon }',
    },
    getSquadEnergy: {
      title: 'Энергия восстановлена',
      buttonText: 'Получить',
    },
  },
  invitedSquad: {
    title: 'Вы получили приглашение от этой команды',
  },
  squadsList: {
    yourSquadHead: 'Твой сквад',
    listHead: 'Топ сквады',
    competition: 'Соревнование',
  },
  squadsEmpty: {
    text: 'Ещё никто не создал сквады. Будь первым, кто создаст его',
    buttonText: 'Создать сквад',
  },
  squadItem: {
    buttonJoin: 'Вступить',
    buttonLeave: 'Покинуть',
    commission: 'комиссия',
    place: 'место',
  },
  popupSquad: {
    joinSquad: 'Присоединиться к скваду',
  },
  popupNewSquad: {
    head: 'Создай свой сквад',
    text: 'Получай до {value}% от всех монет, добытых участниками сквада',
    rulesTitle: 'Правила',
    rulesList: {
      coinsLost: '{icon} <b>{value}</b> останется на балансе',
      energyLost: ({ plural, named }: PluralNamed) =>
        plural([
          `${named('icon')} <b>${named('value')}</b> будет потеряна`,
          `${named('icon')} <b>${named('value')}</b> будет потеряно`,
          `${named('icon')} <b>${named('value')}</b> будет потеряно`,
        ]),
      friendsInvited: ({ plural, named }: PluralNamed) =>
        plural([
          `${named('icon')} <b>${named('value')}</b>
          друг получит приглашение в твой сквад`,
          `${named('icon')} <b>${named('value')}</b>
          друга получат приглашение в твой сквад`,
          `${named('icon')} <b>${named('value')}</b>
          друзей получат приглашение в твой сквад`,
        ]),
      cantFarm: 'Ты больше не сможешь фармить лутбоксы',
    },
    createTitle: 'Создать сквад',
    createRequirement:
      'Добавь <span class="highlited">{botNickname}</span> в свой Telegram канал или чат перед созданием сквада',
    inputLabel: 'Ссылка на <span>свой(!)</span> Telegram-канал/чат',
    buttonText: 'Cоздать сквад за {price}',
    errors: {
      invalidUrl: 'Пожалуйста, проверь URL-адрес',
      invalidDomain: 'Ссылка должна начинаться с t.me',
      noEnergy: 'Недостаточно энергии для создания сквада',
      exists: 'Такой сквад уже существует',
    },
  },
  popupSquadInvite: {
    head: 'Приглашение вступить в сквад',
    join: 'Вступить',
    decline: 'Отклонить',
  },
  popupJoinSquad: {
    head: 'Ты вступил в сквад',
    ok: 'Ок',
  },
  popupLeaveSquad: {
    head: 'Хочешь покинуть сквад?',
    in: 'через',
    text: 'Выход из сквада сбросит таймер восстановления энергии',
    stay: 'Остаться',
    leave: 'Покинуть',
  },
  popupChangeSquad: {
    head: 'Предложение\nсменить сквад',
    squadFromHead: 'Ваш текущий сквад',
    squadToHead: 'Новый сквад',
    buttonChange: 'Сменить сквад',
    buttonDecline: 'Отклонить',
  },
  boxPreview: {
    buttonText: 'Разбить бокс',
  },
  boxGameScene: {
    tap: tap,
    buttonInviteText: 'Пригласить друга',
    buttonCollectReward: 'Получить',
  },
  popupInviteTerms: {
    head: 'Твоя награда за приглашение друга',
    text: 'Ты получишь награду, когда друг нафармит {userThreshold} монет',
    ok: 'Ок',
  },
  popupGetReward: {
    // TODO поменять текст модалки
    head: 'Получи свою награду',
    rewards: {
      friends: ({ plural, named }: PluralNamed) =>
        plural([
          `Твоя награда за ${named('value')} приглашенного друга`,
          `Твоя награда за ${named('value')} приглашенных друзей`,
          `Твоя награда за ${named('value')} приглашенных друзей`,
        ]),
      telegramSubscribed: 'Ты успешно подписался\nна телеграм канал',
    },
    ok: 'Получить',
  },
  noSquadInfo: {
    buttonCreateSquad: 'Создать свой сквад',
    or: 'или',
    joinSquadText:
      'Вступи в сквад и получай <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> каждые <b>8</b> часов',
  },
  mySquadManager: {
    head: 'Статистика',
    inviteButton: ' Пригласить в сквад',
  },
  squadStats: {
    income: 'Доход',
    members: 'Члены сквада',
  },
  squadFeeRange: {
    title: 'Комиссия сквада',
    description: 'Чем ниже комиссия сквада,\nтем выше рейтинг сквада',
  },
  sort: {
    today: 'Сегодня',
    week: 'Неделя',
  },
  errorPage: {
    title: 'Произошла ошибка',
    text: 'К сожалению, произошла ошибка. Пожалуйста, попробуйте перезагрузить приложение или зайдите позже.',
    buttonReloadText: 'Перезагрузить',
    buttonSupprotText: 'Написать в поддержку',
  },
}

<template>
  <div class="app-device">
    <div class="app-device__text" v-if="isConnectionClosed">
      {{ $t('tools.disconnectedMessage') }}
    </div>
    <div class="app-device__text" v-else-if="isMobile && isLandscape">
      {{ $t('tools.rotateMessage') }}
    </div>
    <template v-else>
      <img
        class="app-device__title"
        src="@/assets/images/play-on-mobile.jpg"
        alt="QR code to open bot"
        v-if="$i18n.locale === 'en-EN'"
      />
      <div v-else class="app-device__text">
        {{ $t('tools.playOnMobile') }}
      </div>
      <img
        class="app-device__qr"
        src="@/assets/images/bot-qr-code.png"
        alt="QR code to open bot"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppDevice',
  computed: {
    ...mapState('device', {
      isLandscape: (state) => state.isLandscape,
      isMobile: (state) => state.isMobile,
      isConnectionClosed: (state) => state.isConnectionClosed,
    }),
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/helpers.scss';
.app-device {
  display: flex;
  flex-direction: column;
  background: black;
  box-shadow: 0 100vh 0 100vh black;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__title {
    max-width: 300px;
  }

  &__text {
    max-width: 500px;
    color: $color-cyan;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
    filter: drop-shadow(0 0 5px rgba($color-cyan, 0.5));
    padding: 20px;
    white-space: pre-line;
  }

  &__qr {
    max-width: 80vw;
    width: 300px;
  }
}
</style>

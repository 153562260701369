<template>
  <button :class="bem('button-action', { color, loading, size, disabled })">
    <div :class="bem('button-action__container', { size, color })">
      <span class="button-action__slot"><slot /></span>
      <span v-if="icon" :class="bem('button-action__icon', { type: icon })" />
    </div>
    <SpinnerBlock v-if="loading" class="button-action__spinner-block" />
  </button>
</template>

<script>
import SpinnerBlock from './SpinnerBlock.vue'
export default {
  components: { SpinnerBlock },
  name: 'ButtonAction',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['medium', 'big'].includes(value)
      },
    },
    color: {
      type: String,
      default: 'green',
      validator(value) {
        return ['green', 'cyan', 'pink', 'transparent', 'yellow'].includes(
          value
        )
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
      validator(value) {
        return ['energy-green', 'external-link'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';
.button-action {
  display: inline-flex;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  background: initial;
  border: initial;
  outline: initial;
  position: relative;
  --corner-size: 20rem;
  --corner-inside-coeff: 1.25;
  --corner-outsied-coeff: 0.7;
  --corner-shadow-size: 10rem;
  --corner-outside-color: transparent
  --border-color: transparent;
  --background-color: transparent;
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding-block-end: 0;
  padding-block-start: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s transform;

  &:active {
    transform: scale(0.95);
  }

  &_color {
    &_green {
      color: white;
      --border-color: #{$color-green-light};
      --background-color: #{$color-green-dark};
      --corner-outside-color: #{$color-green}
    }

    &_cyan {
      color: white;
      --border-color: #{$color-cyan-light};
      --background-color: #{$color-cyan-extra-dark};
      --corner-outside-color: #{$color-pink};
    }

    &_pink {
      color: white;

      --border-color: #{$color-pink-white};
      --background-color: #{$color-pink-dark};
      --corner-outside-color: #{$color-pink};
    }

    &_transparent {
      color: white;

      --border-color: #{$color-violet-pale};
      --background-color: transparent;
      --corner-outside-color: #{$color-violet-pale};

      .button-action__container {
        background-image: initial !important;
      }
    }

    &_yellow {
      color: white;

      --border-color: #{$color-yellow-white};
      --background-color: #{$color-yellow-medium-dark};
      --corner-outside-color: #{$color-yellow};
    }
  }

  &_size {
    &_medium {
      --corner-size: 15rem;
      --corner-inside-coeff: 1.3;
      --corner-outsied-coeff: 0.73;
      --corner-shadow-size: 5rem;

      --height:40rem;
      --padding: 0 5rem;
      --font-size:12rem;

      .button-action__icon {
        width: 20rem;
        height: 20rem;
        margin-left: 3rem;
      }
    }

    &_big {
      --height:  55rem;
      --padding:: 0 8rem;
      --font-size: 16rem;

      .button-action__icon {
        width: 24rem;
        height: 24rem;
        margin-left: 6rem;
      }
    }
  }

  &_loading {
    pointer-events: none;

    .button-action__slot {
      opacity: 0.5;
    }
    .button-action__icon {
      opacity: 0.5;
    }
    .button-action__container {
      opacity: 0.5;
    }

    &::before,
    &::after {
      opacity: 0.5;
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    filter: drop-shadow(0 0 var(--corner-shadow-size) var(--corner-outside-color));
  }

  &::before {
    top: 0;
    left: 0;
    border-right: calc(var(--corner-size) * var(--corner-outsied-coeff)) solid
      transparent;
    border-top: calc(var(--corner-size) * var(--corner-outsied-coeff)) solid
      var(--corner-outside-color);
  }

  &::after {
    bottom: 0;
    right: 0;
    border-top: calc(var(--corner-size) * var(--corner-outsied-coeff)) solid
      transparent;
    border-right: calc(var(--corner-size) * var(--corner-outsied-coeff)) solid
      var(--corner-outside-color);
  }

  &__container {
    box-shadow: 0 0 0 3rem var(--border-color) inset;
    background-color: var(--background-color);
    background-size: 6rem;
    background-repeat: repeat;
    background-position: left top;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    height: var(--height);
    padding: var(--padding);
    font-size: var(--font-size);

    clip-path: polygon(
      var(--corner-size) 0%,
      100% 0%,
      100% calc(100% - var(--corner-size)),
      calc(100% - var(--corner-size)) 100%,
      0% 100%,
      0% var(--corner-size)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
      border-right: calc(var(--corner-size) * var(--corner-inside-coeff)) solid
        transparent;
      border-top: calc(var(--corner-size) * var(--corner-inside-coeff)) solid
        var(--border-color);
    }

    &::after {
      bottom: 0;
      right: 0;
      border-top: calc(var(--corner-size) * var(--corner-inside-coeff)) solid
        transparent;
      border-right: calc(var(--corner-size) * var(--corner-inside-coeff)) solid
        var(--border-color);
    }
  }

  &__icon {
    display: block;
    flex-shrink: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;

    &_type {
      &_energy-green {
        background-image: url(@/assets/icons/energy-green.svg);
        filter: drop-shadow(0 0 5rem rgba($color-green, 0.3));
      }

      &_external-link {
        // background-image: url(@/assets/icons/external-link.svg);
      }
    }
  }

  &__spinner-block {
    position: absolute;
    font-size: var(--font-size);
  }
}
</style>

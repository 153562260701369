import { createStore, GetterTree, MutationTree, ActionTree } from 'vuex'
import { share } from '@/lib/telegram'
import auth from '@/lib/auth'
import { preloadAssets } from '@/lib/preloadAssets'

import device from './modules/device'
import constants from './modules/constants'
import user from './modules/user'
import notifications from './modules/notifications'
import squadsTop from './modules/squadsTop'
import errors from './modules/errors'
import eventManager from './modules/eventManager'

const state: RootState = {
  telegramId: null,
  boxGameOpened: false,
  isMainLoadingShow: true,
  joinChannelLoading: false,
  energyBoxesByAdd: 3,
  avilableEnergyBoxes: 0,
}

const getters: GetterTree<RootState, RootState> = {
  refererLink(state): string {
    return `https://t.me/${process.env.VUE_APP_BOT_NICKNAME}/start?startapp=${state.telegramId}`
  },
}

const mutations: MutationTree<RootState> = {
  openBoxGame(state) {
    state.boxGameOpened = true
  },
  closeBoxGame(state) {
    state.boxGameOpened = false
  },
  setTelegramId(state, id: string) {
    state.telegramId = id
  },
  setMainLoadingShow(state, value: boolean) {
    state.isMainLoadingShow = value
  },
  setJoinChannelLoading(state, value: boolean) {
    state.joinChannelLoading = value
  },
  setAvilableEnergyBoxes(state, value: number) {
    state.avilableEnergyBoxes = value
  },
  DecAvilableEnergyBoxes(state) {
    state.avilableEnergyBoxes =
      state.avilableEnergyBoxes > 0 ? 0 : state.avilableEnergyBoxes--
  },
}

const actions: ActionTree<RootState, RootState> = {
  watchAdd({ state, commit }) {
    commit('setAvilableEnergyBoxes', state.energyBoxesByAdd)
  },
  inviteFriend({ getters }) {
    share(getters.refererLink)
  },
  joinChannel() {
    setTimeout(() => {
      window.open(process.env.VUE_APP_BOT_JOIN_CHANNEL_LINK)
    })
  },
  async initApp({ commit, dispatch }) {
    commit('setMainLoadingShow', true)
    await dispatch('device/init', { root: true })
    await preloadAssets()

    const user = await auth.authorize()

    if (user) {
      dispatch('eventManager/trackEvent', { eventType: 'gameStarted' })

      dispatch('eventManager/initEventManager', user.id)

      commit('user/setUser', { user }, { root: true })
    } else {
      dispatch('eventManager/trackEvent', { eventType: 'gameStartedDemo' })
    }

    commit('setMainLoadingShow', false)
  },
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    device,
    constants,
    user,
    notifications,
    squadsTop,
    errors,
    eventManager,
  },
})

<template>
  <div :class="bem('progress-bar', { size, color })">
    <div
      v-if="hasContent"
      :class="bem('progress-bar__content', { align: valueAlign })"
    >
      <div class="progress-bar__title">
        <slot name="title" />
      </div>

      <div class="progress-bar__value">
        <img
          v-if="!noIcon"
          class="progress-bar__value-icon"
          src="@/assets/icons/energy-white.svg"
          alt="Energy"
        />

        {{ valueText }}
      </div>
    </div>

    <div
      class="progress-bar__filling"
      :style="{ width: `${progress * 100}%` }"
    />
  </div>
</template>

<script>
const SIZE_SMALL = 'small'
const SIZE_NORMAL = 'normal'
const SIZE_SLIM = 'slim'

export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    valueAlign: {
      type: String,
      default: 'center',
      validator(value) {
        return ['center', 'right'].includes(value)
      },
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    maxValueVisible: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'green',
      validator(value) {
        return ['green', 'green-intense', 'cyan'].includes(value)
      },
    },
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return [SIZE_SMALL, SIZE_NORMAL, SIZE_SLIM].includes(value)
      },
    },
  },
  computed: {
    progress() {
      return this.value / this.maxValue
    },
    valueText() {
      let valueText = this.formatNumber(this.value)

      if (this.maxValueVisible) {
        const maxValueText = this.formatNumber(this.maxValue)

        valueText = `${valueText} / ${maxValueText}`
      }

      return valueText
    },
    hasContent() {
      return [SIZE_NORMAL, SIZE_SMALL].includes(this.size)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.progress-bar {
  position: relative;
  background-color: $color-green-dark;

  &_size {
    &_small {
      height: 20px;
      padding: 0 5rem;

      .progress-bar__value {
        font-size: 12rem;
      }
    }

    &_normal {
      height: 32rem;
      padding: 0 8rem;

      .progress-bar__value {
        font-size: 20rem;
      }
    }

    &_slim {
      height: 4rem;
    }
  }

  &_color {
    &_green {
      .progress-bar__filling {
        background-color: $color-green;
        box-shadow: 0 0 8px 0 $color-green;
      }
    }

    &_green-intense {
      .progress-bar__filling {
        background-color: $color-green-intense;
        box-shadow: 0 0 8px 0 $color-green-intense;
      }
    }
    &_cyan {
      .progress-bar__filling {
        background-color: $color-cyan;
        box-shadow: 0 0 8px 0 $color-cyan;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    height: 100%;

    &_align {
      &_center {
        justify-content: center;
      }

      &_right {
        justify-content: flex-end;
      }
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 16rem;
    font-weight: 500;
  }

  &__filling {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
  }

  &__value {
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 1.5;
  }

  &__value-icon {
    width: 22rem;
    margin-right: 4rem;
  }
}
</style>

<template>
  <div class="box-preview">
    <div class="box-preview__image" />

    <ButtonAction
      class="box-preview__button"
      color="green"
      size="big"
      @click="openBoxGame"
      :loading="loading"
    >
      <img src="@/assets/icons/ad.svg" class="box-preview__button-icon" />
      {{ $t('boxPreview.buttonText') }}
    </ButtonAction>
  </div>
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue'
import gsap from 'gsap'
import api from '@/api'

export default {
  name: 'BoxPreview',
  components: { ButtonAction },
  data: () => ({
    loading: false,
  }),
  methods: {
    async openBoxGame() {
      try {
        // this.loading = true
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'StartBreakingLootboxes',
        })
        // await this.$store.dispatch('user/fetchUser')
        await this.$store.dispatch('watchAdd')
        this.$store.commit('openBoxGame')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    gsap.to(this.$el, {
      top: '0',
      opacity: 1,
      duration: 0.5,
    })
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.box-preview {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -40rem;

  &__health {
    width: 160rem;
    margin-bottom: 8rem;
  }

  $mainBoxSteps: 24;
  $mainBoxSize: 155rem;

  &__image {
    width: $mainBoxSize;
    height: $mainBoxSize;
    background: left no-repeat url(@/assets/images/box-main-sprite.png);
    background-size: auto 100%;
    border: initial;
    animation: mainBoxAnimate 0.7s steps($mainBoxSteps) infinite,
      scaleAnimate 2s ease-in-out infinite;
    margin-top: -5rem;
    pointer-events: none;
  }

  @keyframes mainBoxAnimate {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: calc(-#{$mainBoxSize * $mainBoxSteps}) 0;
    }
  }

  @keyframes scaleAnimate {
    0%,
    100% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
  }

  &__button {
    width: 300rem;
    margin-bottom: 20rem;

    &-icon {
      height: 1em;
      margin-right: 0.2em;
      margin-bottom: -0.13em;
    }
  }
}
</style>

<template>
  <div class="game-content">
    <div class="game-content__content">
      <LootBoxPreview />
    </div>
  </div>
</template>

<script>
import LootBoxPreview from '@/components/BoxPreview.vue'

export default {
  name: 'GameContent',
  components: {
    LootBoxPreview,
  },
  data: () => ({
    activeTabId: '',
    firstLoad: true,
  }),
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.game-content {
  --padding-left-right: 16rem;

  display: flex;
  flex-direction: column;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
  }
}
</style>

<template>
  <div class="box-game-scene" @pointerdown="hit()" @touchstart="onTouchStart">
    <div v-if="loading && isExplodeVisible" class="box-game-scene__loading">
      <SpinnerBlock class="box-game-scene__spinner-block" />
    </div>
    <div class="box-game-scene__content">
      <ProgressBar
        class="box-game-scene__health"
        ref="health"
        :max-value="maxHealth"
        :value="health"
        color="green"
        no-icon
        max-value-visible
        size="normal"
      />

      <div ref="box" :class="bem('box-game-scene__box', { damage })">
        <div
          v-show="isExplodeVisible"
          ref="boxExplode"
          :class="bem('box-game-scene__box-explode', { type: explodeType })"
        />
        <div
          v-show="isAppearVisible"
          ref="boxAppear"
          class="box-game-scene__box-appear"
        />
      </div>

      <div
        v-if="hasEnergy && isGameTipVisible"
        ref="gameTip"
        class="box-game-scene__bottom-container"
      >
        <div class="box-game-scene__tap">
          <img
            class="box-game-scene__tap-image"
            :src="$t('boxGameScene.tap')"
          />
        </div>
        <img
          ref="tapIcon"
          class="box-game-scene__tap-icon"
          src="@/assets/images/hand-lined.png"
        />
      </div>
      <div
        v-show="isRewardVisible"
        ref="rewardContainer"
        class="box-game-scene__reward-container"
      >
        <div class="box-game-scene__reward-value">
          {{ formatNumber(crushedValue) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProgressBar from '@/components/ProgressBar.vue'
import gsap from 'gsap'
import { formatNumber } from '@/lib/utils'
import eventBus from '@/lib/eventBus'
import api from '@/api'
import SpinnerBlock from '../SpinnerBlock.vue'

export default {
  name: 'BoxGameScene',
  components: { ProgressBar, SpinnerBlock },
  emits: ['before-crush', 'reload', 'close'],
  data: () => ({
    isHitTweenPlaying: false,
    isSpawnPlaying: false,
    isGameTipVisible: true,
    crushedValue: 0,
    isExplodeVisible: false,
    isAppearVisible: false,
    isRewardVisible: false,
    collectLoading: false,
    hits: [],
    explodeType: 'energy',
    direction: 'down',
    lockHit: true,
    energyAfterGame: 0,
    // Для отладки
    scaleLines: [0.17, 0.5, 0.77],
    loading: false,
    canSkipReward: false,
  }),
  async mounted() {
    this.animateHand()

    if (this.health === this.maxHealth) {
      this.appear()
    }

    let loadingTimeout

    const loadingPromise = new Promise((resolve) => {
      loadingTimeout = setTimeout(() => {
        this.loading = true
        resolve()
      }, 1000)
    })

    const dataPromise = (async () => {
      if (this.user) {
        const { earnedEnergy, energy } = await api.users.crashBox()

        this.crushedValue = earnedEnergy
        this.energyAfterGame = energy

        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'energyCollected',
          eventProperties: { amount: earnedEnergy },
        })
      } else {
        this.crushedValue = Math.floor(Math.random() * 5) + 1
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'energyCollectedDemo',
          eventProperties: { amount: this.crushedValue },
        })
      }
    })()

    try {
      await Promise.race([dataPromise, loadingPromise])
      await dataPromise
    } catch (error) {
      console.error('Error loading data:', error)
    } finally {
      clearTimeout(loadingTimeout)
      this.loading = false
    }

    this.lockHit = false
  },

  unmounted() {
    if (this._handTween) {
      this._handTween.kill()
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('constants', ['constants']),
    totalHits() {
      return this.hits.reduce((acc, hit) => acc + hit, 0)
    },
    maxHealth() {
      // Подклюсить к апи
      return 5
    },
    health() {
      return Math.max(this.maxHealth - this.totalHits, 0)
    },
    damage() {
      let damage = 0

      if (this.health < 3) {
        return (damage = 2)
      }

      if (this.health < 2) {
        return (damage = 2)
      }

      if (this.health < this.maxHealth) {
        return (damage = 1)
      }

      return damage
    },
    hasEnergy() {
      return this.energy > 0
    },
    canHit() {
      return (
        this.hasEnergy &&
        this.health > 0 &&
        !this.isHitTweenPlaying &&
        !this.lockHit &&
        !this.isAppearVisible
      )
    },
    energy() {
      // TODO выпилить
      return Infinity
    },
  },
  methods: {
    formatNumber,
    generateRandomNumber(min, max) {
      return Math.random() * (max - min) + min
    },
    async appear() {
      this.isAppearVisible = true

      await this.$nextTick()

      this._appearTl = gsap.timeline()
      const duration = 0.3

      eventBus.emit('play-sound', 'spawn')

      this._appearTl.to(this.$refs.boxAppear, {
        backgroundPositionX: '100%',
        duration: 0.7,
        ease: 'steps(24)',
        onComplete: () => {
          this.isAppearVisible = false
        },
      })
      this._appearTl.from(this.$refs.scaleContainer, {
        x: '80rem',
        duration: duration,
      })
      this._appearTl.from(
        this.$refs.health.$el,
        {
          delay: 0.2,
          y: '-100rem',
          opacity: 0,
          duration: duration,
        },
        '<'
      )

      if (this.$refs.gameTip) {
        this._appearTl.from(
          this.$refs.gameTip,
          {
            y: '100rem',
            opacity: 0,
            duration: duration,
          },
          '<'
        )
      }

      this._appearTl.then()
    },
    animateHand() {
      this._handTween = gsap.to(this.$refs.tapIcon, {
        scale: 0.7,
        ease: 'linear',
        repeat: -1,
        duration: 0.3,
        yoyo: true,
      })
    },
    getHitPower() {
      return 1
    },
    async hit() {
      if (this.isAppearVisible && this._appearTl) {
        this._appearTl.timeScale(0.5)
        this.isAppearVisible = false
        gsap.to(this.$refs.health.$el, {
          y: '0',
          opacity: 1,
        })
        eventBus.emit('play-sound')
      }

      if (this.canSkipReward && this._explodeTl) {
        this._explodeTl.clear()

        gsap.to(this.$refs.box, {
          backgroundImage: 'initial',
        })

        gsap.to(this.$refs.boxExplode, {
          opacity: 0,
          duration: 0.1,
          onComplete: () => {
            this.$emit('reload')
          },
        })
      }

      if (!this.canHit) {
        return
      }

      this.isHitTweenPlaying = true

      const hitPower = this.getHitPower()

      this.hits.push(hitPower)

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'hitLootbox',
      })

      eventBus.emit('play-sound', `hit${hitPower}`)

      this.safeVibrate()

      const isLastHit = this.totalHits >= this.maxHealth
      const isGameTipVisible = this.isGameTipVisible

      if (isLastHit) {
        this.lockHit = true
      }

      if (isLastHit) {
        if (this._boxShake) {
          this._boxShake.clear()
        }
        this.getGameResults()
        return
      }

      if (isGameTipVisible) {
        this._handTween.kill()

        gsap.to(this.$refs.gameTip, {
          duration: 0.5,
          opacity: 0,
          ease: 'power1.inOut',
          onComplete: () => {
            this.isGameTipVisible = false
          },
        })
      }

      this._boxShake = gsap
        .timeline()
        .to(this.$refs.box, {
          duration: 0.15,
          scale: 0.75,
          ease: 'power1.inOut',
          yoyo: true,
          repeat: 1,
        })
        .to(
          this.$refs.box,
          {
            duration: 0.05,
            x: '10',
            ease: 'power1.inOut',
            yoyo: true,
            repeat: 5,
          },
          0
        )
        .to(
          this.$refs.box,
          {
            duration: 0.05,
            x: '-10',
            ease: 'power1.inOut',
            yoyo: true,
            repeat: 5,
          },
          0.25
        )
        .to(this.$refs.box, {
          duration: 0,
          x: '0',
        })
      this.isHitTweenPlaying = false
    },
    getGameResults() {
      // TODO подключить к апи
      this.crush()
    },
    crush() {
      this.$emit('before-crush')

      eventBus.emit('play-sound', 'crush')

      gsap.to(this.$refs.scaleContainer, {
        opacity: 0,
        duration: 0.5,
      })

      // TODO TypeError Cannot read properties of null (reading '$el')
      // GSAP target null not found.
      if (this.$refs.health.$el) {
        gsap.to(this.$refs.health.$el, {
          opacity: 0,
          duration: 0.5,
        })
      }

      this._explodeTl = gsap.timeline()

      this.isExplodeVisible = true

      const stepsMap = {
        energy: 23,
      }
      setTimeout(() => {
        this.isRewardVisible = true
      }, 200)

      setTimeout(() => {
        this.canSkipReward = true
      }, 500)

      this._explodeTl.to(this.$refs.boxExplode, {
        backgroundPositionX: '100%',
        duration: 1,
        ease: `steps(${stepsMap[this.explodeType]})`,
        onComplete: () => {
          this.energyAfterGame
          this.$store.commit('user/setUserEnergy', this.energyAfterGame)
          this._explodeTl.to(this.$refs.box, {
            backgroundImage: 'initial',
          })
          eventBus.emit('play-sound', `collect_energy`)
        },
      })
      this._explodeTl.to(this.$refs.box, {
        scale: 0.4,
        duration: 0.2,
      })
      this._explodeTl.fromTo(
        this.$refs.rewardContainer,
        {
          opacity: 0,
          y: '60rem',
        },
        {
          opacity: 1,
          y: '20rem',
          duration: 0.3,
          ease: 'power2.out',
        },
        '-=1'
      )

      const closeTl = gsap.timeline({
        delay: 2,
        onComplete: () => {
          this.$emit('reload')
          // this.$emit('close')
        },
      })

      closeTl.to(
        this.$refs.rewardContainer,
        {
          opacity: 0,
          duration: 0.3,
          y: '40rem',
        },
        '0'
      )
      closeTl.to(
        this.$refs.boxExplode,
        {
          opacity: 0,
          duration: 0.3,
          y: '40rem',
        },
        '0'
      )
    },
    onTouchStart(e) {
      if (this.canHit) {
        e.preventDefault()
      }
    },
    safeVibrate() {
      if (navigator.vibrate) {
        try {
          navigator.vibrate(200)
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.box-game-scene {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__reward-head {
    position: absolute;
    z-index: 20;
    top: 8rem;
    left: 50%;
    transform: translateX(-50%);
    width: 347rem;
    opacity: 0;
  }

  &__health {
    width: 170rem;
    margin-bottom: 12rem;
  }

  &__image {
    width: 165rem;
  }

  &__hit-power-container {
    position: absolute;
    z-index: 20;
    top: -70%;
    left: 50%;
    transform: translateX(-50%);
    height: 200rem;
  }

  &__hit-power {
    height: 100%;
    opacity: 0;
  }

  &__box {
    position: relative;
    z-index: 10;
    width: 166rem;
    height: 167rem;
    background: center no-repeat;
    background-size: contain;
    pointer-events: none;

    &_damage {
      &_0 {
        background-image: url(@/assets/images/box.png);
      }

      &_1 {
        background-image: url(@/assets/images/box-1.png);
      }

      &_2 {
        background-image: url(@/assets/images/box-2.png);
      }
    }
  }

  &__box-appear {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 510rem;
    height: 510rem;
    background: left no-repeat url(@/assets/images/box-appear-sprite.png);
    background-size: auto 100%;
    pointer-events: none;
  }

  &__box-explode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 510rem;
    height: 510rem;
    background-size: auto 100%;
    background-position: left;
    background-repeat: no-repeat;
    pointer-events: none;

    &_type {
      &_energy {
        background-image: url(@/assets/images/box-explode-energy-sprite.png);
      }
    }
  }

  &__bottom-container {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tap {
    display: flex;
    align-items: center;
  }

  &__tap-image {
    width: 130rem;
  }

  &__tap-icon {
    width: 106rem;
  }

  &__no-energy-icon {
    width: 120rem;
  }

  &__no-energy-text {
    height: 77rem;
    margin-top: -40rem;
  }

  &__button-invite-friend {
    width: 300rem;
  }

  &__reward-container {
    position: absolute;
    z-index: 20;
    top: 88%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
  }

  &__reward-value {
    margin-bottom: 30rem;
    font-size: 42rem;
    font-weight: bold;
  }

  &__button-collect-reward {
    width: 300rem;
  }

  &__scale-container {
    position: absolute;
    top: 0;
    right: 20rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.15s ease-in opacity;

    &_lock {
      opacity: 0.6;
    }
  }

  &__scale {
    position: relative;
    width: 36rem;
  }

  &__scale-image {
    width: 100%;
  }

  &__scale-track {
    position: absolute;
    top: 9%;
    right: 100%;
    width: 0;
    height: 82%;
    // width: 1px;
    // background: red;
  }

  &__scale-line {
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 50rem;
    background: red;
    font-size: 12rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: red;
  }

  &__scale-slider {
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 10rem 0 10rem 12rem;
    border-color: transparent transparent transparent $color-pink;
    transform: translateY(-50%);
  }

  &__loading {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  &__spinner-block {
    font-size: 24rem;
  }
}
</style>

<template>
  <div class="page-main">
    <div class="page-main__overlay" />
    <MainHeader class="page-main__header" v-if="user" />
    <BoxGame v-if="$store.state.boxGameOpened" class="page-main__box-game" />
    <GameContent v-else class="page-main__game-content" />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import GameContent from '@/components/GameContent.vue'
import BoxGame from '@/components/BoxGame/BoxGame.vue'
import api from '@/api'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    BoxGame,
    GameContent,
    MainHeader,
  },
  data: () => ({
    invitedSquad: {},
    joinLoading: false,
    squadInfoLoading: false,
    hideLoading: false,
  }),
  computed: {
    ...mapState('user', ['user']),
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.page-main {
  display: flex;
  flex-direction: column;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
  }

  &__logo {
    position: relative;
    z-index: 10;
    display: block;
    width: 400rem;
    margin: 10rem auto 10rem;
  }

  &__launch-progress {
    position: relative;
    z-index: 10;
    margin: 0 15rem;
  }

  &__game-content {
    position: relative;
    z-index: 10;
  }

  &__box-game {
    position: fixed !important;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
